<template>
  <!-- 积分兑换优惠券 -->
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="130px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取消</el-button>
      <!-- <el-button type="primary" @click="dialogEvent(dialog.click + '-save')"
          >保存并新增</el-button
        > -->
      <el-button type="primary" @click="dialogEvent(dialog.click)">保存</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { goodsPackingSave, goodsPackingUpdate } from "@/api/system/goods/packing";

export default {
  name: "Packing",
  mixins: [mixin],
  data() {
    return {
      requests: { add: goodsPackingSave, update: goodsPackingUpdate },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      {
        model: "packingNo",
        type: "input",
        label: "包装编号",
        maxlength: 20,
        rules: [
          {
            required: true,
            message: "请输入包装编号",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "packingName",
        type: "input",
        label: "包装名称",
        maxlength: 40,
        rules: [
          {
            required: true,
            message: "请输入包装名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "packingWeight",
        type: "input",
        label: "包装重量",
        inputType: "number",
        rules: [
          {
            required: true,
            message: "请输入包装重量",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "remark",
        type: "textarea",
        label: "备注",
        maxlength: 80,
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
