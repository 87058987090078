<template>
  <div class="dialog-content" v-if="options">
    <div class="content">
      <TablePage v-model="options"> </TablePage>
    </div>
    <ul style="display: none;">
      <li >
        {{ options.check.length }}
      </li>
    </ul>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">关 闭</el-button>
      <el-button :disabled="options.check.length <= 0" type="primary" @click="handleRestore">还 原</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from '@/components/Dialog/components/mixin.js'
import { baseInfo } from '@/components/Dialog/components/tableconfig.js'

export default {
  name: 'ShopInfo',
  components: { TablePage: () => import('@/components/tablePage/index.vue') },
  mixins: [mixin],
  data() {
    return {
      options: null
    }
  },
  created() {
    //   search: [
    //   {
    //     type: "input",
    //     tip: "级别编码/级别名称",
    //     model: "",
    //     filter: "query",
    //     isRestore: true,
    //   },
    //   {
    //     type: "button",
    //     tip: "查询",
    //     btnType: "primary",
    //     click: "search",
    //  isLeft:true,
    //     isRestore: true,
    //   },
    // ],
    // console.log(this.dialog.data, 'this.dialog.data')
    this.dialog.data.rowKey=this.dialog.data.rowKey?this.dialog.data.rowKey:this.dialog.data.id
    this.options = { ...baseInfo, ...this.dialog.data,...{noShowColumnSet:true} }
  },
  methods: {
    async handleRestore() {
      let arrId = ''
      if (this.data.type === 'decoration') {
        arrId = this.options.check.map(v => v.workerId)
      } else {
        arrId = this.options.check.map(v => v[this.options.id])
      }
      await this.options.restoreListApi(arrId)
      this.$emit('dialogEvent', 'dialogChange')
      this.$message.success('还原成功')
      // console.log('this.formthis.form', this.form)
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto;
  }
  ::v-deep .el-card {
    margin-bottom: 0px !important;
    border: none !important;
    box-shadow: none !important;
    .cardContent {
      padding: 0 !important;
    }
  }
}
</style>
