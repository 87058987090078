<template>
  <div>
    <div class="dialog-content">
      <el-form label-width="100px" :model="form">
        <el-form-item label="管理员手机号">
          <el-input
            :type="inputType"
            v-model="form.phoneNumber"
            autocomplete="new-password"
          >
            <template slot="suffix">
              <img
                class="pointer"
                @click="visiblePassword"
                :src="isShowPasswordIcon"
                alt="加载失败"
              />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <div style="display: flex">
            <el-input v-model="form.code" type="number"> </el-input>
            <el-button
              type="primary"
              class="codeBtn"
              style="margin-left: 10px"
              @click="getCode()"
              :disabled="isDisabled"
              >{{ btnMsg }}
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="confirm()">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { sendCode, smsVerifyCode } from "@/api/system/user"; //短信接口
export default {
  name: "VerificationAdmin",
  data() {
    return {
      form: {
        phoneNumber: undefined,
        code: undefined,
      },
      btnMsg: "获取验证码",
      isDisabled: false,
      adminPhone: [15118036503], // 张雪彬手机号 商户管理员手机号
      inputType: "password",
      isShowPasswordIcon: require("@/assets/imgs/loginimgs/hidePW-icon.png"),
    };
  },
  async created() {
    // 商户管理员手机号
    if (this.$store.state.user?.userinfo?.tenantTelephone) {
      this.adminPhone.push(Number(this.$store.state.user.userinfo.tenantTelephone));
    }
  },
  methods: {
    //是否显示手机号
    visiblePassword() {
      this.inputType = this.inputType === "text" ? "password" : "text";
      this.isShowPasswordIcon =
        this.isShowPasswordIcon === require("@/assets/imgs/loginimgs/hidePW-icon.png")
          ? require("@/assets/imgs/loginimgs/showPW-icon.png")
          : require("@/assets/imgs/loginimgs/hidePW-icon.png");
    },
    // 获取验证码
    async getCode() {
      // if (Number(this.form.phoneNumber) !== 15118036503) {
      //   return this.$message.error("请输入正确的管理员手机号");
      // }
      if (!this.adminPhone.includes(Number(this.form.phoneNumber))) {
        return this.$message.error("请输入正确的管理员手机号");
      }
      try {
        let time = 60;
        this.btnMsg = time + "秒后重试";
        this.isDisabled = true;
        await sendCode(Number(this.form.phoneNumber));
        time = 59;
        let timer = setInterval(() => {
          if (time == 0) {
            clearInterval(timer);
            this.btnMsg = "获取验证码";
            this.isDisabled = false;
          } else {
            this.btnMsg = time + "秒后重试";
            this.isDisabled = true;
            time--;
          }
        }, 1000);
      } catch {
        this.isDisabled = false;
      }
    },
    async confirm() {
      // if (Number(this.form.phoneNumber) !== 15118036503) {
      //   return this.$message.error("请输入正确的管理员手机号");
      // }
      console.log("进来了嘛", this.form.phoneNumber);
      console.log("进来了嘛", this.adminPhone);
      if (!this.adminPhone.includes(Number(this.form.phoneNumber))) {
        return this.$message.error("请输入正确的管理员手机号");
      }
      if (!this.form.code || this.form.code.length !== 6) {
        return this.$message.error("请输入正确的验证码");
      }
      try {
        const { status } = await smsVerifyCode({
          telephone: Number(this.form.phoneNumber),
          code: this.form.code,
        });
        if (status === "success") {
          this.$emit(
            "dialogEvent",
            "exportFileSuccess",
            Number(this.form.phoneNumber) === 15118036503 ? false : true
          );
        } else {
          this.$message.error("请输入正确的验证码");
        }
      } catch (err) {
        this.$message.error(err.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
</style>
