<template>
  <div class="dialog-content">
    <el-form ref="form" label-width="100px" :rules="rules" :model="form">
      <el-form-item label="指定周">
        <div class="x-f">
          <el-radio-group v-model="form.DateTimeList[0].specialDateWeekMode">
            <el-radio
              v-for="dict in dict.type.special_date_week_mode"
              :key="dict.value"
              :label="dict.value"
              :value="dict.value"
              @click.native.prevent="clickRadio(dict.value)"
              >{{ dict.label }}</el-radio
            >
          </el-radio-group>
          <el-form-item v-if="form.DateTimeList[0].specialDateWeekMode !== '2'">
            <el-input disabled />
          </el-form-item>
          <div v-if="form.DateTimeList[0].specialDateWeekMode === '2'">
            <el-form-item
              :prop="`DateTimeList[${0}].specialDateWeekNum`"
              :rules="rules[`DateTimeList.specialDateWeekNum`]"
            >
              <el-input v-model="form.DateTimeList[0].specialDateWeekNum" />
            </el-form-item>
          </div>
          <span>周</span>
        </div>
      </el-form-item>
      <el-form-item label="指定星期">
        <el-checkbox-group v-model="form.DateTimeList[1].specialDateWeeksArr">
          <el-checkbox label="1">星期一</el-checkbox>
          <el-checkbox label="2">星期二</el-checkbox>
          <el-checkbox label="3">星期三</el-checkbox>
          <el-checkbox label="4">星期四</el-checkbox>
          <el-checkbox label="5">星期五</el-checkbox>
          <el-checkbox label="6">星期六</el-checkbox>
          <el-checkbox label="7">星期日</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="每月期间">
        <div class="marB20 x-f">
          <el-checkbox v-model="form.DateTimeList[2].isTopMonthPeriod" label="每月第" />
          <!-- 显示的文本框 -->
          <el-form-item v-if="!form.DateTimeList[2].isTopMonthPeriod">
            <el-input />
          </el-form-item>
          <!-- 实际输入的文本框 -->
          <!-- 校验 -->
          <div v-if="form.DateTimeList[2].isTopMonthPeriod">
            <el-form-item
              :prop="`DateTimeList[${2}].topFromDay`"
              :rules="rules[`DateTimeList.topFromDay`]"
            >
              <el-input v-model="form.DateTimeList[2].topFromDay" class="practical" />
            </el-form-item>
          </div>
          <!-- </el-form> -->
          <span>至</span>
          <!-- 显示的文本框 -->
          <el-form-item v-if="!form.DateTimeList[2].isTopMonthPeriod">
            <el-input />
          </el-form-item>
          <!-- 实际输入的文本框 -->
          <!-- 校验 -->
          <div v-if="form.DateTimeList[2].isTopMonthPeriod">
            <el-form-item
              :prop="`DateTimeList[${2}].topToDay`"
              :rules="rules[`DateTimeList.topToDay`]"
            >
              <el-input v-model="form.DateTimeList[2].topToDay" />
            </el-form-item>
          </div>
          <span>天</span>
        </div>
        <div class="x-f">
          <el-checkbox
            v-model="form.DateTimeList[2].isDownMonthPeriod"
            label="每月最后"
          />
          <!-- 显示的文本框 -->
          <el-form-item v-if="!form.DateTimeList[2].isDownMonthPeriod">
            <el-input />
          </el-form-item>
          <!-- 实际输入的文本框 -->
          <div v-if="form.DateTimeList[2].isDownMonthPeriod">
            <el-form-item
              :prop="`DateTimeList[${2}].downFromDay`"
              :rules="rules[`DateTimeList.downFromDay`]"
            >
              <el-input v-model="form.DateTimeList[2].downFromDay" />
            </el-form-item>
          </div>
          <span>至</span>
          <!-- 显示的文本框 -->
          <el-form-item v-if="!form.DateTimeList[2].isDownMonthPeriod">
            <el-input />
          </el-form-item>
          <!-- 实际输入的文本框 -->
          <div v-if="form.DateTimeList[2].isDownMonthPeriod">
            <el-form-item
              :prop="`DateTimeList[${2}].downToDay`"
              :rules="rules[`DateTimeList.downToDay`]"
            >
              <el-input v-model="form.DateTimeList[2].downToDay" />
            </el-form-item>
          </div>
          <span>天</span>
        </div>
      </el-form-item>
      <el-form-item label="指定日期">
        <el-checkbox-group v-model="form.DateTimeList[3].specialDayArr" class="assign">
          <el-checkbox
            v-for="item in dateList"
            :key="item"
            :label="item"
            class="checkNum"
            >{{ item }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="指定时段">
        <el-time-picker
          v-model="form.DateTimeList[4].specialBegTime"
          style="width: 200px"
          value-format="HH:mm:ss"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="开始时间"
        >
        </el-time-picker>
        至
        <el-time-picker
          v-model="form.DateTimeList[4].specialEndTime"
          style="width: 200px"
          value-format="HH:mm:ss"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="结束时间"
        >
        </el-time-picker>
        <!-- <el-time-picker
          v-model="timeHorizon"
          is-range
          unlink-panels
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          format="HH:mm:ss"
          value-format="HH:mm:ss"
        /> -->
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleEvent('close')">取 消</el-button>
      <el-button type="primary" @click="handleEvent('confirm')"> 确 定 </el-button>
    </div>
  </div>
</template>
<script>
import { deepClone } from "@/utils";
export default {
  name: "TimeDateSelect",
  dicts: ["special_date_week_mode"], // 周范围
  model: { prop: "formData", event: "Device" },
  props: {
    formData: {
      type: Object,
    },
    dialog: {
      type: Object,
    },
  },
  data() {
    return {
      num: 2,
      timeHorizon: undefined, // 时间范围,
      // 日期范围
      dateList: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      // 表单
      form: {
        DateTimeList: [
          { specialDateWeekMode: undefined, specialDateWeekNum: undefined },
          { specialDateWeeksArr: [] },
          {
            isTopMonthPeriod: false,
            isDownMonthPeriod: false,
            topFromDay: undefined,
            topToDay: undefined,
            downFromDay: undefined,
            downToDay: undefined,
          },
          { specialDayArr: [] },
          { specialBegTime: undefined, specialEndTime: undefined },
        ], // 日期
      },
      // 校验
      rules: {
        "DateTimeList.topFromDay": [
          {
            required: true,
            message: "请输入第几天",
            trigger: ["blur", "change"],
          },
        ],
        "DateTimeList.topToDay": [
          {
            required: true,
            message: "请输入第几天",
            trigger: ["blur", "change"],
          },
        ],
        "DateTimeList.downToDay": [
          {
            required: true,
            message: "请输入最后几天",
            trigger: ["blur", "change"],
          },
        ],
        "DateTimeList.downFromDay": [
          {
            required: true,
            message: "请输入最后几天",
            trigger: ["blur", "change"],
          },
        ],
        "DateTimeList.specialDateWeekNum": [
          {
            required: true,
            message: "请输入第几周",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    // 侦听对话框开关
    "dialog.show": {
      handler(val) {
        if (val) {
          this.reset();
          console.log(this.form, "this.form", this.formData);
          if (this.formData?.DateTimeList?.length >= 1) {
            // 回显
            this.formData.DateTimeList.forEach((item) => {
              // this.form.DateTimeList[item.specialDateType] = deepClone(item);
              this.$set(this.form.DateTimeList, item.specialDateType, deepClone(item));
            });
          }
        }
      },
      immediate: true,
    },
    // 时间范围
    timeHorizon(newVal) {
      if (newVal) {
        this.form.DateTimeList[4].specialBegTime = newVal[0];
        this.form.DateTimeList[4].specialEndTime = newVal[1];
      } else {
        this.form.DateTimeList[4].specialBegTime = undefined;
        this.form.DateTimeList[4].specialEndTime = undefined;
      }
    },
  },
  methods: {
    timeFilter(time) {
      if (time) {
        let s = "";
        let hour = time.split(":")[0];
        let min = time.split(":")[1];
        let sec = time.split(":")[2];
        s = Number(hour * 3600) + Number(min * 60) + Number(sec);
        return s;
      } else {
        return time;
      }
    },
    async handleEvent(type, row) {
      switch (type) {
        case "confirm":
          if (
            this.form.DateTimeList[4].specialBegTime ||
            this.form.DateTimeList[4].specialEndTime
          ) {
            let BegTime = this.timeFilter(this.form.DateTimeList[4].specialBegTime);
            let EndTime = this.timeFilter(this.form.DateTimeList[4].specialEndTime);
            if (
              !(BegTime == 0 ? true : BegTime ?? false) ||
              !(EndTime == 0 ? true : EndTime ?? false)
            ) {
              return this.$message.warning("请输入完整指定时间");
            } else if (BegTime > EndTime) {
              return this.$message.warning("开始时间不能大于结束时间");
            }
          }
          this.$emit("Device", this.form);
          this.$emit("dialogEvent", "dialogChange");
          break;
        case "close":
          this.$emit("dialogEvent", "dialogClose");
          break;
        default:
          break;
      }
    },
    // 对话框关闭的回调
    handleClose() {
      this.reset();
      this.$emit("update:OpenDate", false);
    },
    // 初始化数据
    reset() {
      this.timeHorizon = undefined;
      this.form.DateTimeList = [
        { specialDateWeekMode: undefined, specialDateWeekNum: undefined },
        { specialDateWeeksArr: [] },
        {
          isTopMonthPeriod: false,
          isDownMonthPeriod: false,
          topFromDay: undefined,
          topToDay: undefined,
          downFromDay: undefined,
          downToDay: undefined,
        },
        { specialDayArr: [] },
        { specialBegTime: undefined, specialEndTime: undefined },
      ]; // 日期
    },
    // 确定按钮事件
    getConfirm() {
      this.$refs["form"].validate(async (valid) => {
        // 表单校验
        if (valid) {
          this.$emit("getDateTimeList", this.form.DateTimeList);
          this.reset();
          this.$emit("update:OpenDate", false);
        } else {
          this.$message.error("请把关联的必填项输入完整");
        }
      });
    },
    // 指定周单选,再次点击清空
    clickRadio(val) {
      this.form.DateTimeList[0].specialDateWeekMode =
        val === this.form.DateTimeList[0].specialDateWeekMode ? undefined : val;
    },
  },
};
</script>

<style lang="scss" scoped>
//表单文本显示(向左对齐)
::v-deep.el-form-item__label {
  text-align: left !important;
}
.el-form {
  padding: 20px 20px 0;
}
.el-input {
  //文本框宽度
  width: 100px;
  margin: 0 5px;
}
//指定周
.week {
  display: flex;
}
//指定日期
.assign {
  width: 450px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  padding-right: 10px;
  .el-checkbox {
    margin-right: 10px;
  }
}
</style>
