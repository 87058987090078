var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialog-content" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "x-f" }, [
            _c(
              "div",
              {
                staticClass: "marB10 fontBold",
                staticStyle: { "margin-right": "50px" },
              },
              [_vm._v(_vm._s("工艺名称：" + _vm.dialog.typeInfo.craftLineName))]
            ),
            _c("div", { staticClass: "marB10 fontBold" }, [
              _vm._v(_vm._s("工艺编号：" + _vm.dialog.typeInfo.craftLineNo)),
            ]),
          ]),
          _c("TablePage", {
            ref: "tablePageGoodsDia",
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options,
              callback: function ($$v) {
                _vm.options = $$v
              },
              expression: "options",
            },
          }),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: {
          title: "新增工序",
          width: 500,
          showDialog: _vm.dialogFormVisibleTwo,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisibleTwo = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisibleTwo = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "ruleFormGoodsDialog",
                    attrs: {
                      model: _vm.formGoodsDialog,
                      rules: _vm.rules,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "商品", prop: "goodsId" } },
                      [
                        _c("SelectRemote", {
                          attrs: {
                            option: _vm.$select({
                              key: "listGoods",
                            }).option,
                          },
                          model: {
                            value: _vm.formGoodsDialog.goodsId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formGoodsDialog, "goodsId", $$v)
                            },
                            expression: "formGoodsDialog.goodsId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否默认路线", prop: "isDefault" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.formGoodsDialog.isDefault,
                              callback: function ($$v) {
                                _vm.$set(_vm.formGoodsDialog, "isDefault", $$v)
                              },
                              expression: "formGoodsDialog.isDefault",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: true } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: false } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submit } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }