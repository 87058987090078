<template>
  <div class="dialog-content">
    <div class="content">
      <div class="x-f">
        <div class="marB10 fontBold" style="margin-right:50px">{{`工艺名称：${dialog.typeInfo.craftLineName}`}}</div>
        <div class="marB10 fontBold">{{`工艺编号：${dialog.typeInfo.craftLineNo}`}}</div>
      </div>
      <TablePage ref="tablePageGoodsDia" v-model="options" @handleEvent="handleEvent" />
    </div>
    <c-dialog title="新增工序" :width="500" :showDialog.sync="dialogFormVisibleTwo">
      <template #content>
        <el-form :model="formGoodsDialog" :rules="rules" ref="ruleFormGoodsDialog" label-width="120px">
          <el-form-item label="商品" prop="goodsId">
            <SelectRemote v-model="formGoodsDialog.goodsId" :option="
                  $select({
                    key: 'listGoods'
                  }).option
                " />
          </el-form-item>
          <el-form-item label="是否默认路线" prop="isDefault">
            <el-radio-group v-model="formGoodsDialog.isDefault">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>
<script>
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import {
  listCraftLineGoods,
  addCraftLineGoods,
  delCraftLineGoods
} from "@/api/mes/base/craftLine";
import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "ProcessGoods",
  components: { TablePage: () => import("@/components/tablePage/index.vue"), CDialog, SelectRemote },
  mixins: [mixin],
  data() {
    return {
      dialogFormVisibleTwo: false,//新增、单独修改对话框
      formGoodsDialog: {
        isDefault: false
      }, //对话框表单
      //新增表单校验
      rules: {
        goodsId: [
          {
            required: true,
            message: "关联商品不能为空",
            trigger: ["blur", "change"],
          }
        ],
        isDefault: [
          {
            required: true,
            message: "是否默认路线不能为空",
            trigger: ["blur", "change"],
          }
        ]
      },
      options: {
        mutiSelect: true,
        loading: false,
        getDataKey: { list: "data" },
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        defaultBody: () => {
          return {
            craftLineIds: this.dialog.craftLineIds,
          }
        },
        getListApi: listCraftLineGoods,
        listNo: true, // 序号
        height: '450px',
        check: [], // 选中数据
        rowKey: "billId",
        tableTitle: '',
        hideCard: true,
        noShowColumnSet: true,
        search: [
         {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'goodsNos', label: '商品编码' },
              { filter: 'goodsNames', label: '商品名称' },
              { filter: 'goodsZjms', label: '助记码' },
              { filter: 'goodsCategoryNames', label: '商品类别名称' },
            ]
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: 'addDialog',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary',
            noRule: true
          },
          {
            click: 'handleDeleteDialog',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            alertText: '确认要删除选中的关联商品吗？',
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'goodsNo',
            label: '商品编码',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.goodsInfo?.goodsNo,
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.goodsInfo?.goodsName,
          },
           {
            prop: 'categoryName',
            label: '分类',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.goodsInfo?.categoryName,
          },
           {
            prop: 'goodsSpec',
            label: '规格',
            minWidth: 120,
            align: 'center',
            formatter: (v, row) => row?.goodsInfo?.goodsSpec,
          },
           {
            prop: 'zjm',
            label: '助记码',
            minWidth: 120,
            align: 'center',
            formatter: (v, row) => row?.goodsInfo?.zjm,
          },
           {
            prop: 'isDefault',
            label: '默认路线',
            minWidth: 120,
            align: 'center',
            formatter: (v, row) => row?.isDefault?'是':'否',
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async submit() {
      try {
        await this.$refs["ruleFormGoodsDialog"].validate();
        this.formGoodsDialog = {
          ...this.formGoodsDialog,
          craftLineId: this.dialog.typeInfo.craftLineId,
        };
        await addCraftLineGoods(this.formGoodsDialog);
        this.$message({
          message: "新增关联商品成功~",
          type: "success",
        });
        this.cancel();
        this.$refs.tablePageGoodsDia.getList();
      } catch (error) {
        console.log('error', error);
      }
    },
    //新增取消
    cancel() {
      this.dialogFormVisibleTwo = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.formGoodsDialog = {
        isDefault: false
      };
      this.resetForm("ruleFormGoodsDialog");
    },
    async handleEvent(type, row, rowIndex) {
      let arr = []
      switch (type) {
        case 'addDialog':
          {
            this.reset()
            this.dialogFormVisibleTwo = true
          }
          break
        case 'handleDeleteDialog':
          {
            arr = this.options.check.map(item => ({ lineId: item.lineId }))
            await delCraftLineGoods(arr)
            this.$message.success("删除成功~");
            this.$refs.tablePageGoodsDia.getList();
          }
          break
        default:
          break
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto;
    padding: 0;
  }
  ::v-deep .el-card {
    margin-bottom: 0px !important;
    border: none !important;
    box-shadow: none !important;
    .cardContent {
      padding: 0 !important;
    }
  }
}
</style>

