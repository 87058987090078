<template>
    <div class="dialog-content" v-if="form && show">
      <div class="content">
        <myForm v-model="form" ref="myForm" :options="formOpt" label-width="120px" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
        <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
      </div>
    </div>
  </template>
  <script>
  import { addProcess, updateProcess } from "@/api/decoration/base/process.js";
  import { mixin } from "@/components/Dialog/components/mixin.js";
  export default {
    name: "Process",
    mixins: [mixin],
    data() {
      return {
        requests: { add: addProcess, update: updateProcess },
        show: false,
        formOpt: [],
      };
    },
    async created() {
      const formOpt = [
      {
          model: "processNo",
          type: "input",
          label: "工序编号",
          rules: [
            {
              required: true,
              message: "请输入工序编号",
              trigger: ["blur", "change"],
            },
          ],
          maxlength:20
        },
        {
          model: "processName",
          type: "input",
          label: "工序名称",
          rules: [
            {
              required: true,
              message: "请输入工序名称",
              trigger: ["blur", "change"],
            },
          ],
          maxlength:20
        },
        {
          model: "isFinish",
          type: "switch",
          label: "是否结束工序",
          open:true,
          close:false,
        },
        {
          model: "sortNo",
          type: "number",
          label: "排序",
          option:{disabled:false},
          min:0,
          max:9999
        },
      ];
      await this.format(formOpt);
      this.show = true;
    },
    methods: {},
  };
  </script>
  
  <style lang="scss" scoped></style>
  